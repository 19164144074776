import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../storage/storage.service';
import { NUMBER_CONST } from '../../../constants/number';
import { CryptoService } from '../../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private _storage: StorageService,private readonly _crypto:CryptoService) {}

  authorization(basic = false) {
   const reqPayload={
      isValid:true,
      time:Date.now(),
      code: Math.random().toString(36).substring(2, 15)
  
    }
   const encrypted = this._crypto.encryptValue(reqPayload);
    const token = this._storage.token;

    const auth: any = {
    'Authorization': token && !basic ?  token : 'Basic ' + btoa(`${'selfit'}:${'selfit@123'}`),
    // "Api_key": environment.API_KEY,
    deviceToken: 'dummy',
    deviceId: this._storage.deviceDetail(NUMBER_CONST.TWO),
    'platform': 'Web',
    'accept-language': "en",
    'requestid':encrypted
    };
    return auth;
  }
}
